var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.to && !_vm.anchor)?_c('button',_vm._g(_vm._b({class:{
    primary: _vm.color == 'primary',
    secondary: _vm.color == 'secondary',
    white: _vm.color == 'white',
    outline: _vm.outline === '' || _vm.outline.length,
    outlinePrimary: _vm.outline == 'primary',
    outlineSecondary: _vm.outline == 'secondary',
    outlineWhite: _vm.outline == 'white'
  }},'button',_vm.$attrs,false),_vm.listeners),[_vm._t("default")],2):(_vm.to && _vm.anchor)?_c('a',_vm._g(_vm._b({class:{
    primary: _vm.color == 'primary',
    secondary: _vm.color == 'secondary',
    white: _vm.color == 'white',
    outline: _vm.outline === '' || _vm.outline.length,
    outlinePrimary: _vm.outline == 'primary',
    outlineSecondary: _vm.outline == 'secondary',
    outlineWhite: _vm.outline == 'white'
  },attrs:{"href":_vm.to}},'a',_vm.$attrs,false),_vm.listeners),[_vm._t("default")],2):(!_vm.submit)?_c('router-link',_vm._g(_vm._b({class:{
    primary: _vm.color == 'primary',
    secondary: _vm.color == 'secondary',
    white: _vm.color == 'white',
    outline: _vm.outline === '' || _vm.outline.length,
    outlinePrimary: _vm.outline == 'primary',
    outlineSecondary: _vm.outline == 'secondary',
    outlineWhite: _vm.outline == 'white'
  },attrs:{"to":_vm.to}},'router-link',_vm.$attrs,false),_vm.listeners),[_vm._t("default")],2):_c('button',_vm._g(_vm._b({class:{
    primary: _vm.color == 'primary',
    secondary: _vm.color == 'secondary',
    white: _vm.color == 'white',
    outline: _vm.outline === '' || _vm.outline.length,
    outlinePrimary: _vm.outline == 'primary',
    outlineSecondary: _vm.outline == 'secondary',
    outlineWhite: _vm.outline == 'white'
  },attrs:{"type":"submit"}},'button',_vm.$attrs,false),_vm.listeners),[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }