var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{class:{ menuOpen: _vm.menuOpen }},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"core"},[_c('router-link',{staticClass:"logo",attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("@/assets/logoWhite.svg"),"alt":"Dennis Adamczyk"}})]),_c('button',{staticClass:"menu",class:{ open: _vm.menuOpen },on:{"click":_vm.toggleMenu}},[_c('div',{staticClass:"stroke"}),_c('div',{staticClass:"stroke"}),_c('div',{staticClass:"stroke"})])],1),_c('nav',[_c('ul',[_c('router-link',{class:_vm.$route.path.match(/^\/home$|^\/$/i) && 'router-link-active',attrs:{"to":{
            path: '/',
            params: this.$route.params,
            query: this.$route.query,
          },"replace":_vm.$route.name == 'Home',"active-class":""}},[_c('li',[_vm._v(" "+_vm._s(_vm.$t('header.navigation.home'))+" ")])]),_c('router-link',{class:_vm.$route.name === 'About' && 'router-link-active',attrs:{"to":{
            path: '/about',
            params: this.$route.params,
            query: this.$route.query,
          },"replace":_vm.$route.name == 'Home'}},[_c('li',[_vm._v(" "+_vm._s(_vm.$t('header.navigation.about'))+" ")])]),_c('router-link',{class:_vm.$route.name === 'Portfolio' && 'router-link-active',attrs:{"to":{
            path: '/portfolio',
            params: this.$route.params,
            query: this.$route.query,
          },"replace":_vm.$route.name == 'Home'}},[_c('li',[_vm._v(" "+_vm._s(_vm.$t('header.navigation.portfolio'))+" ")])]),_c('router-link',{class:_vm.$route.name === 'Contact' && 'router-link-active',attrs:{"to":{
            path: '/contact',
            params: this.$route.params,
            query: this.$route.query,
          },"replace":_vm.$route.name == 'Home'}},[_c('li',[_vm._v(" "+_vm._s(_vm.$t('header.navigation.contact'))+" ")])]),_c('li',{staticClass:"language-switcher"},[_c('button',{staticClass:"language-switcher__button tooltip-focus-disable",attrs:{"data-tooltip":_vm.$t('header.switchLanguage')},on:{"click":_vm.switchLanguage}},[_vm._v(" "+_vm._s(_vm.otherLocale)+" ")])])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }