<template>
  <footer>
    <div class="content">
      <p class="copyright">
        <i18n path="footer.copyright">
          <template v-slot:year>{{ new Date().getFullYear() }}</template>
          <template v-slot:vueLogo>
            <a
              data-tooltip="Vue.js"
              target="_blank"
              href="https://vuejs.org/"
              class="vueLogo tooltip--top"
            >
              <i class="icon-vuejs"></i>
            </a>
          </template>
        </i18n>
      </p>
      <nav>
        <ul>
          <router-link to="/privacy">
            <li>{{ $t('footer.navigation.privacy') }}</li>
          </router-link>
          <router-link to="/imprint">
            <li>{{ $t('footer.navigation.imprint') }}</li>
          </router-link>
        </ul>
      </nav>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter',
};
</script>

<style lang="scss" scoped>
footer {
  background-color: $background-light;
  padding: 16px 24px 24px 24px;

  @media all and ($desktop) {
    padding: 24px 24px 32px 24px;

    .content {
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      max-width: $max-view-width;

      .copyright {
        margin: 0 24px 0 0;
        text-align: left;
      }

      nav {
        ul {
          text-align: right;
        }
      }
    }
  }

  .copyright {
    margin: 0 0 24px 0;
    font-size: 0.875rem;
    text-align: center;
    color: $text-color;
    line-height: 1.1875rem;

    .vueLogo {
      display: inline-block;
      margin-left: 2px;
      transform: translateY(2px);
      text-decoration: none;
      color: $text-color;
      outline: none;
    }
  }

  nav {
    ul {
      display: block;
      list-style: none;
      text-align: center;

      a {
        display: inline-block;
        margin-right: 16px;
        color: $text-color;
        text-decoration: none;
        font-size: 0.875rem;
        text-align: center;
        outline: none;
        transition: color 150ms ease;

        &:last-of-type {
          margin-right: 0;
        }

        &:hover {
          color: $foreground;
        }
      }
    }
  }
}
</style>
