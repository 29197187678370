<template>
  <div class="wrapper" :class="{ focused, dirty, invalid, hasValue, box }">
    <label :for="name">{{ label }}</label>
    <input
      v-if="type !== 'textarea'"
      :type="type || 'text'"
      :id="name"
      :name="name"
      ref="input"
      class="input"
      v-bind="$attrs"
      v-on="listeners"
    />
    <textarea
      v-else
      :id="name"
      :name="name"
      ref="input"
      class="input"
      :style="{ resize: autogrow ? 'none' : 'auto' }"
      v-bind="$attrs"
      v-on="listeners"
    ></textarea>
    <span class="error">{{ typeof error == 'string' ? error : error() }}</span>
  </div>
</template>

<script>
export default {
  name: 'BaseInput',
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      required: true,
    },
    type: String,
    label: String,
    validator: Map,
    autogrow: Boolean,
    box: Boolean,
  },
  data() {
    return {
      focused: false,
      invalid: false,
      hasValue: false,
      dirty: false,
      error: '',
    };
  },
  methods: {
    validate(value) {
      if (!(this.validator instanceof Map) || !this.validator.size) return;

      let foundMissmatch = false;
      for (var [regex, error] of this.validator) {
        if (!value.match(regex)) {
          this.error = error;
          this.invalid = true;
          foundMissmatch = true;
          break;
        }
      }
      if (!foundMissmatch) this.invalid = false;
    },
    auto_grow(element) {
      element.style.height = '5px';
      element.style.height = element.scrollHeight + (this.box ? 4 : 0) + 'px';
    },
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        focus: () => (this.focused = true),
        focusout: (event) => {
          this.focused = false;
          this.dirty = true;
          event.target.value = event.target.value.trim();
          this.validate(event.target.value);
        },
        input: (event) => {
          this.hasValue = event.target.value;
          this.validate(event.target.value);
          if (this.autogrow) this.auto_grow(event.target);
          this.$emit('input', event.target.value);
        },
        change: (event) => {
          this.hasValue = event.target.value;
          this.validate(event.target.value);
          if (this.autogrow) this.auto_grow(event.target);
          this.$emit('input', event.target.value);
        },
      };
    },
  },
  mounted() {
    this.validate(this.$refs.input.value);
    this.hasValue = this.$refs.input.value;
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  min-height: 48px;
  margin: 4px 0 24px 0;
  padding-top: 16px;
  display: flex;
  position: relative;
  font-family: inherit;
  box-sizing: border-box;

  &.box {
    &::after,
    &::before {
      display: none;
    }

    &.dirty.invalid {
      .input {
        border-color: $error-color;
      }
    }

    &.focused {
      .input {
        border-color: $primary !important;
      }
    }

    .input {
      border: 2px solid #2d2d2d;
      border-radius: 8px;
      padding: 8px;
      margin: 4px 0 0 0;
      height: 118px;
      min-height: 118px;
      transition: border 150ms ease-in-out;
    }

    label {
      pointer-events: auto;
      top: 0;
      opacity: 1;
      font-size: 0.75rem;
    }
  }

  &::before,
  &::after {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
    transition: border 150ms ease-in-out, opacity 150ms ease-in-out,
      transform 0s ease-in-out 150ms;
    will-change: border, opacity, transform;
    content: '';
  }

  &.dirty.invalid {
    label {
      color: $error-color;
    }

    &::after {
      background-color: $error-color;
    }

    .error {
      color: $error-color;
      opacity: 1;
      transform: translateZ(0);
    }
  }

  &.focused {
    &::before {
      opacity: 1;
      transform: scaleX(1);
      transition: 150ms ease-in-out;
      transition-property: border, opacity, transform;
    }

    label {
      color: $primary !important;
      pointer-events: auto;
      top: 0;
      opacity: 1;
      font-size: 0.75rem;
    }
  }

  &::before {
    height: 2px;
    z-index: 2;
    opacity: 0;
    transform: scaleX(0.12);
    background-color: $primary;
  }

  &::after {
    background-color: #2d2d2d;
    // background-color: rgba($foreground, 0.08);
    height: 2px;
  }

  &.hasValue {
    label {
      pointer-events: auto;
      top: 0;
      opacity: 1;
      font-size: 0.75rem;
    }

    .input {
      -webkit-text-fill-color: rgba($foreground, 0.87);
      font-size: 1rem;
    }
  }

  label {
    position: absolute;
    top: 22px;
    left: 0;
    width: 100%;
    pointer-events: none;
    transition: 150ms ease-in-out;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.25rem;
    color: $text-color;
  }

  .input {
    height: 32px;
    min-height: 32px;
    padding: 0;
    display: block;
    flex: 1;
    border: none;
    background: none;
    transition: 150ms ease-in-out;
    transition-property: font-size, padding-top, color;
    font-family: inherit;
    font-size: 1rem;
    line-height: 2rem;
    outline: none;
    color: $foreground;
    caret-color: $foreground;
    box-sizing: border-box;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      box-shadow: 0 0 0 30px $background inset !important;
      -webkit-text-fill-color: $foreground !important;
    }
  }

  textarea.input {
    line-height: normal;
    padding: 4px 0;
  }

  .error {
    display: block;
    left: 0;
    opacity: 0;
    transform: translate3d(0, -8px, 0);
    height: 20px;
    position: absolute;
    bottom: -22px;
    font-size: 0.75rem;
    transition: 150ms ease-in-out;
  }
}
</style>
