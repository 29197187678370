import Vue from 'vue';
import VueRouter from 'vue-router';
import VueMeta from 'vue-meta';

Vue.use(VueRouter);
Vue.use(VueMeta);

const routes = [
  {
    path: '/',
    alias: ['/home', '/about', '/portfolio', '/contact'],
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '@/pages/Home'),
  },
  {
    path: '/portfolio/designer-vintage-clothing',
    name: 'DesignerVintageClothing',
    component: () =>
      import(
        /* webpackChunkName: "designerVintageClothing" */ '@/pages/Portfolio/DesignerVintageClothing'
      ),
  },
  {
    path: '/portfolio/fhg-online',
    name: 'FhgOnline',
    component: () =>
      import(/* webpackChunkName: "fhgOnline" */ '@/pages/Portfolio/FhgOnline'),
  },
  {
    path: '/portfolio/continuum-graphics-store',
    name: 'ContinuumGraphicsStore',
    component: () =>
      import(
        /* webpackChunkName: "continuumGraphicsStore" */ '@/pages/Portfolio/ContinuumGraphicsStore'
      ),
  },
  {
    path: '/portfolio/fhg-news',
    alias: ['/portfolio/fhgnews'],
    name: 'FhgNews',
    component: () =>
      import(/* webpackChunkName: "fhgNews" */ '@/pages/Portfolio/FhgNews'),
  },
  {
    path: '/imprint',
    name: 'Imprint',
    component: () =>
      import(/* webpackChunkName: "imprint" */ '@/pages/Imprint'),
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () =>
      import(/* webpackChunkName: "privacy" */ '@/pages/Privacy'),
  },
  {
    // catch all 404 - define at the very end
    path: '*',
    component: () => import('@/pages/NotFound'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    const animationDuration = 100;

    if (to.name == 'Home') {
      if (from.name != 'Home' && savedPosition)
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(savedPosition);
          }, animationDuration);
        });
      return false;
    }

    if (savedPosition)
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(savedPosition);
        }, animationDuration);
      });

    if (to.hash)
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            selector: to.hash,
            offset: { y: 64 },
          });
        }, animationDuration);
      });

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 });
      }, animationDuration);
    });
  },
  routes,
});

export default router;
