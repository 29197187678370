<template>
  <header :class="{ menuOpen }">
    <div class="content">
      <div class="core">
        <router-link to="/" class="logo">
          <img src="@/assets/logoWhite.svg" alt="Dennis Adamczyk" />
        </router-link>
        <button class="menu" :class="{ open: menuOpen }" @click="toggleMenu">
          <div class="stroke"></div>
          <div class="stroke"></div>
          <div class="stroke"></div>
        </button>
      </div>
      <nav>
        <ul>
          <router-link
            :to="{
              path: '/',
              params: this.$route.params,
              query: this.$route.query,
            }"
            :replace="$route.name == 'Home'"
            active-class=""
            :class="$route.path.match(/^\/home$|^\/$/i) && 'router-link-active'"
          >
            <li>
              {{ $t('header.navigation.home') }}
            </li>
          </router-link>
          <router-link
            :to="{
              path: '/about',
              params: this.$route.params,
              query: this.$route.query,
            }"
            :replace="$route.name == 'Home'"
            :class="$route.name === 'About' && 'router-link-active'"
          >
            <li>
              {{ $t('header.navigation.about') }}
            </li>
          </router-link>
          <router-link
            :to="{
              path: '/portfolio',
              params: this.$route.params,
              query: this.$route.query,
            }"
            :replace="$route.name == 'Home'"
            :class="$route.name === 'Portfolio' && 'router-link-active'"
          >
            <li>
              {{ $t('header.navigation.portfolio') }}
            </li>
          </router-link>
          <router-link
            :to="{
              path: '/contact',
              params: this.$route.params,
              query: this.$route.query,
            }"
            :replace="$route.name == 'Home'"
            :class="$route.name === 'Contact' && 'router-link-active'"
          >
            <li>
              {{ $t('header.navigation.contact') }}
            </li>
          </router-link>
          <li class="language-switcher">
            <button
              class="language-switcher__button tooltip-focus-disable"
              :data-tooltip="$t('header.switchLanguage')"
              @click="switchLanguage"
            >
              {{ otherLocale }}
            </button>
          </li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  name: 'AppHeader',
  data() {
    return {
      menuOpen: false,
    };
  },
  methods: {
    switchLanguage() {
      this.$i18n.locale = this.otherLocale;
      document.documentElement.setAttribute('lang', this.$i18n.locale);
      if (this.$route.query.lang) {
        this.$router.replace({
          ...this.$router.currentRoute,
          query: {},
        });
      }
    },
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    resizeHandler() {
      if (document.documentElement.clientWidth >= 600 && this.menuOpen)
        this.menuOpen = false;
    },
  },
  computed: {
    otherLocale() {
      let current = this.$i18n.locale;
      for (let i = 0; i < this.$i18n.availableLocales.length; i++) {
        const locale = this.$i18n.availableLocales[i];
        if (locale != current) return locale;
      }
      return 'NONE';
    },
  },
  watch: {
    $route() {
      this.menuOpen = false;
    },
  },
  created() {
    window.addEventListener('resize', this.resizeHandler);
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeHandler);
  },
};
</script>

<style lang="scss" scoped>
header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 56px;
  margin: 0;
  padding: 0;
  box-shadow: map-get($elevation, 1);
  background-color: rgba($background, 0.95);
  overflow: hidden;
  will-change: height;
  transition: height 200ms ease-in-out;
  z-index: 16;

  @media all and ($desktop) {
    overflow: visible;
    height: 64px;
  }

  &.menuOpen {
    height: 100%;

    .content {
      padding-bottom: 0;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px 24px;

    @media all and ($desktop) {
      flex-direction: row;
      max-width: $max-view-width;
      margin: 0 auto;
      padding: 16px 24px;
    }

    .core {
      display: flex;
      justify-content: space-between;
      width: 100%;

      @media all and ($desktop) {
        width: auto;
      }

      .logo {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        outline: none;

        img {
          height: 24px;
          width: auto;
          transition: height 200ms ease;

          @media all and ($desktop) {
            height: 32px;
          }
        }
      }

      .menu {
        position: relative;
        width: 24px;
        height: 24px;
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer;
        z-index: 1;

        @media all and ($desktop) {
          display: none;
        }

        &.open .stroke {
          &:nth-child(1) {
            width: 16px;
            top: 11px;
            transform: rotate(45deg);
          }

          &:nth-child(2) {
            width: 16px;
            transform: rotate(-45deg);
          }

          &:nth-child(3) {
            top: 11px;
            right: 3px;
            width: 16px;
            transform: rotate(45deg);
          }
        }

        .stroke {
          height: 2px;
          border-radius: 2px;
          background-color: $foreground;
          position: absolute;
          transition: all 200ms ease;

          &:nth-child(1) {
            width: 14px;
            top: 6px;
            right: 3px;
          }

          &:nth-child(2) {
            width: 18px;
            top: 11px;
            right: 3px;
          }

          &:nth-child(3) {
            width: 10px;
            top: 16px;
            right: 3px;
          }
        }
      }
    }

    nav {
      @media all and ($mobile) {
        height: calc(100vh - 54px);
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: auto;
        margin: 15px 0 0 0;
      }

      ul {
        display: flex;
        height: 100%;
        list-style: none;
        cursor: default;

        @media all and ($mobile) {
          flex-direction: column;
          align-items: center;
          height: auto;
        }

        a {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          width: 96px;
          margin: 0 8px;
          text-decoration: none;
          color: $foreground;
          outline: none;
          transition: color 150ms ease, font-weight 150ms ease;

          @media all and ($mobile) {
            position: relative;
            width: auto;
            height: auto;
            padding: 4px;
            margin: 0 0 10px 0;

            &:last-of-type::after {
              display: none;
            }

            &::after {
              content: '';
              display: block;
              background-color: rgba($foreground, 0.2);
              width: 1px;
              height: 16px;
              position: absolute;
              top: 23px;
              left: 50%;
              transform: translateX(-50%);
            }
          }

          &.router-link-active {
            color: $primary;
            font-weight: bold;
          }
        }

        li {
          padding: 0;
          font-size: 1.125rem;

          &.language-switcher {
            padding: 0;

            @media all and ($mobile) {
              padding: 14px 0 0 0;
            }

            .language-switcher__button {
              height: 100%;
              padding: 0 0 0 8px;
              background-color: transparent;
              border: none;
              font-size: 0.75rem;
              text-transform: uppercase;
              color: $text-color;
              outline: none;
              cursor: pointer;

              &::before {
                text-transform: initial;

                @media all and ($mobile) {
                  display: none;
                }
              }

              @media all and ($mobile) {
                height: auto;
                padding: 8px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
