import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import i18n from './i18n';
import './components/ui/_globals';
import VueAnalytics from 'vue-analytics';

Vue.config.productionTip = false;

// Configuration VueAnalytics
Vue.use(VueAnalytics, {
  id: 'UA-115758672-1',
  router,
  ignoreRoutes: ['/', '/home', '/about', '/portfolio', '/contact'],
});

new Vue({
  router,
  i18n,
  render: (h) => h(App),
  mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
}).$mount('#app');
