<template>
  <div id="app">
    <AppHeader />
    <main>
      <transition name="view" mode="out-in">
        <router-view />
      </transition>
    </main>
    <AppFooter />
  </div>
</template>

<script>
import AppHeader from '@/components/layout/AppHeader';
import AppFooter from '@/components/layout/AppFooter';

export default {
  components: {
    AppHeader,
    AppFooter,
  },
  created() {
    let lang = new URL(location.href).searchParams.get('lang');
    if (lang && this.$i18n.availableLocales.includes(lang)) {
      this.$i18n.locale = lang;
      document.documentElement.setAttribute('lang', lang);
    } else {
      const userLanguage = navigator.language || navigator.userLanguage;
      if (!userLanguage.toLowerCase().includes('de')) {
        this.$i18n.locale = 'en';
        document.documentElement.setAttribute('lang', 'en');
      }
    }
  },
  watch: {
    $route(to, from) {
      if (!from.name && this.$route.hash) {
        let hash = this.$route.hash.startsWith('#')
          ? this.$route.hash.substr(1)
          : this.$route.hash;

        setTimeout(() => {
          let hashElem = document.getElementById(hash);
          if (hashElem) {
            hashElem.scrollIntoView();
            window.scrollBy({ top: -64, behavior: 'smooth' });
          }
        }, 100);
      }
    },
  },
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
body {
  width: 100%;
  max-width: 100vw;
  height: 100%;
  box-sizing: border-box;
  line-height: normal;
  font-size: 16px;
  background-color: $background;
  color: $text-color;
}

#app {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  min-height: 100%;
  font-family: 'Nunito Sans', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  & > main {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 54px;
    flex-grow: 1;

    @media all and ($desktop) {
      padding-top: 64px;
    }

    & > * {
      max-width: 100%;
    }

    .view-enter-active {
      transition: opacity 200ms ease-in-out, transform 200ms ease-out;
    }

    .view-enter {
      transform: translateY(16px);
      opacity: 0;
    }

    .view-enter-to {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

[data-tooltip] {
  position: relative;

  &.tooltip--top::before {
    top: auto;
    bottom: calc(100% + 0.5rem);
    transform-origin: bottom;
  }

  &.tooltip--left {
    &::before {
      top: 50%;
      left: auto;
      right: calc(100% + 0.5rem);
      transform-origin: right;
      transform: translateY(-50%) scale(0.5);
    }

    &:hover,
    &:focus {
      &::before {
        transform: translateY(-50%) scale(1);
      }
    }
  }

  &.tooltip--right {
    &::before {
      top: 50%;
      left: calc(100% + 0.5rem);
      transform-origin: left;
      transform: translateY(-50%) scale(0.5);
    }

    &:hover,
    &:focus {
      &::before {
        transform: translateY(-50%) scale(1);
      }
    }
  }

  &::before {
    content: attr(data-tooltip);
    position: absolute;
    top: calc(100% + 0.5rem);
    left: 50%;
    box-sizing: border-box;
    padding: 4px 8px;
    transform: translateX(-50%) scale(0.5);
    transition: transform 200ms ease-in-out, opacity 150ms ease-in-out,
      visibility 200ms ease-in-out;
    transform-origin: top;
    visibility: hidden;
    opacity: 0;

    background: rgba(#616161, 0.9);
    color: $foreground;
    border-radius: 2px;
    font-size: 0.75rem;
    font-weight: 400;
    text-align: center;

    z-index: 1;
  }

  &:hover,
  &:focus:not(.tooltip-focus-disable) {
    &::before {
      transform: translateX(-50%) scale(1);
      visibility: visible;
      opacity: 1;
      transition: transform 150ms ease-in-out, opacity 200ms ease-in-out,
        visibility 200ms ease-in-out;
    }
  }
}
@font-face {
  font-family: 'dennisAdamczykIcons';
  src: url('/fonts/dennisAdamczykIcons.eot?6t0qck');
  src: url('/fonts/dennisAdamczykIcons.eot?6t0qck#iefix')
      format('embedded-opentype'),
    url('/fonts/dennisAdamczykIcons.ttf?6t0qck') format('truetype'),
    url('/fonts/dennisAdamczykIcons.woff?6t0qck') format('woff'),
    url('/fonts/dennisAdamczykIcons.svg?6t0qck#dennisAdamczykIcons')
      format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'dennisAdamczykIcons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-social-linkedin:before {
  content: '\e912';
}
.icon-social-twitter:before {
  content: '\e913';
}
.icon-social-xing:before {
  content: '\e914';
}
.icon-open-in-new:before {
  content: '\e911';
}
.icon-right:before {
  content: '\e90f';
}
.icon-left:before {
  content: '\e910';
}
.icon-shipping:before {
  content: '\e900';
}
.icon-social-discord:before {
  content: '\e901';
}
.icon-social-email:before {
  content: '\e902';
}
.icon-social-github:before {
  content: '\e903';
}
.icon-social-instagram:before {
  content: '\e904';
}
.icon-vuejs:before {
  content: '\e905';
}
.icon-brush:before {
  content: '\e906';
}
.icon-bulb:before {
  content: '\e907';
}
.icon-code:before {
  content: '\e908';
}
.icon-eyedropper:before {
  content: '\e909';
}
.icon-leaf:before {
  content: '\e90a';
}
.icon-material-design:before {
  content: '\e90b';
}
.icon-money:before {
  content: '\e90c';
}
.icon-paypal:before {
  content: '\e90d';
}
.icon-responsive:before {
  content: '\e90e';
}
</style>
