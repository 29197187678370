<template>
  <button
    v-if="!to && !anchor"
    :class="{
      primary: color == 'primary',
      secondary: color == 'secondary',
      white: color == 'white',
      outline: outline === '' || outline.length,
      outlinePrimary: outline == 'primary',
      outlineSecondary: outline == 'secondary',
      outlineWhite: outline == 'white'
    }"
    v-bind="$attrs"
    v-on="listeners"
  >
    <slot></slot>
  </button>
  <a
    v-else-if="to && anchor"
    :href="to"
    :class="{
      primary: color == 'primary',
      secondary: color == 'secondary',
      white: color == 'white',
      outline: outline === '' || outline.length,
      outlinePrimary: outline == 'primary',
      outlineSecondary: outline == 'secondary',
      outlineWhite: outline == 'white'
    }"
    v-bind="$attrs"
    v-on="listeners"
  >
    <slot></slot>
  </a>
  <router-link
    v-else-if="!submit"
    :to="to"
    :class="{
      primary: color == 'primary',
      secondary: color == 'secondary',
      white: color == 'white',
      outline: outline === '' || outline.length,
      outlinePrimary: outline == 'primary',
      outlineSecondary: outline == 'secondary',
      outlineWhite: outline == 'white'
    }"
    v-bind="$attrs"
    v-on="listeners"
  >
    <slot></slot>
  </router-link>
  <button
    v-else
    :class="{
      primary: color == 'primary',
      secondary: color == 'secondary',
      white: color == 'white',
      outline: outline === '' || outline.length,
      outlinePrimary: outline == 'primary',
      outlineSecondary: outline == 'secondary',
      outlineWhite: outline == 'white'
    }"
    v-bind="$attrs"
    v-on="listeners"
    type="submit"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'BaseButton',
  inheritAttrs: false,
  props: {
    color: {
      type: String,
      validator: item => item !== 'primary' || item !== 'secondary'
    },
    outline: {
      type: [String, Boolean],
      validator: item =>
        item !== 'primary' ||
        item !== 'secondary' ||
        item !== 'white' ||
        item !== ''
    },
    to: [String, Object],
    submit: Boolean,
    anchor: Boolean
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners
      };
    }
  }
};
</script>

<style lang="scss" scoped>
button,
a {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  padding: 8px 24px;
  min-height: 48px;
  border-radius: 100px;
  font-family: 'Nunito Sans', Arial, sans-serif;
  font-size: 1rem;
  text-align: center;
  color: $foreground;
  outline: none;
  text-decoration: none;
  box-sizing: border-box;
  cursor: pointer;
  transition: background 150ms ease;

  @media all and ($mobile) {
    min-height: 40px;
  }

  &:hover:not([disabled]):not(.disabled) {
    background-color: rgba($foreground, 0.2);
  }

  &[disabled],
  &.disabled {
    opacity: 0.5;
  }

  &.primary {
    background-color: $primary;

    &:hover:not([disabled]):not(.disabled) {
      background-color: $primary-light;
    }
  }

  &.secondary {
    background-color: rgba($foreground, 0.1);

    &:hover:not([disabled]):not(.disabled) {
      background-color: rgba($foreground, 0.2);
    }
  }

  &.white {
    background-color: $foreground;
    color: $background;

    &:hover:not([disabled]):not(.disabled) {
      background-color: rgba($foreground, 0.9);
    }
  }

  &.outline {
    background-color: transparent;

    &.primary,
    &.outlinePrimary {
      border: 1px solid $primary;

      &:hover:not([disabled]):not(.disabled) {
        background-color: rgba($primary, 0.2);
      }
    }

    &.secondary,
    &.outlineSecondary {
      border: 1px solid rgba($foreground, 0.1);

      &:hover:not([disabled]):not(.disabled) {
        background-color: rgba($foreground, 0.2);
      }
    }

    &.white,
    &.outlineWhite {
      border: 1px solid $foreground;

      &:hover:not([disabled]):not(.disabled) {
        background-color: rgba($foreground, 0.2);
      }
    }
  }
}
</style>
